import React, { Component } from 'react'
import './Profile.css'

const INITIAL_STATE = {
    email: '',
    error: null,
    waiting: false,
    userData: null
};

class Profile extends Component {
    constructor(props) {
        super(props)

        this.state = { ...INITIAL_STATE }
        this.setUserData = this.setUserData.bind(this)
        this.toggleEdit = this.toggleEdit.bind(this)
    }

    componentDidMount() {
        this.props.firebase.auth.onAuthStateChanged(function(user) {
            if (user) {
                this.setState({ userLoggedIn: true, email: user.email })
                this.props.firebase.getUserInfo(user.uid)
                .then((res) => {
                    this.setUserData(res);
                })
            } else {
                this.setState({ userLoggedIn: false });
            }
        }.bind(this));
    }
    
    setUserData = (newData) => {
        this.setState({ userData: newData });
    }

    toggleEdit = () => {
        console.log("TEST");
    }

    render() {
        const {
            email,
            error,
            waiting,
            userData
        } = this.state;

        return (
            <div>
                {
                    userData && (
                        <div className="profile-information-container">
                            <div className="line"></div>
                            <div className="row text">
                                {userData.name}
                            </div>
                            <div className="row profile-information-text">
                                email: {email}
                            </div>
                            <div className="row profile-information-text">
                                phone: {userData.phone ? userData.phone : "no phone number"}
                            </div>
                            <div className="row profile-information-text">
                                location: {userData.location.longitude != 0 ? userData.location.longitude : "no location"}
                            </div>
                            <div className="editbtn-container">
                                <button className="btn btn-primary btn-edit text" type="button" onClick={this.toggleEdit}>
                                    edit profile
                                </button>
                            </div>
                            <div className="line"></div>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default Profile